<template>
  <v-card>
    
  <h1 class="ml-4">
    Permissão de Acessos
  </h1>
  <br />
    <v-dialog v-model="modal" persistent max-width="600px">
      <v-card>
      <v-card-title>
        <span class="text-h5"> {{ tituloModal }}</span>
      </v-card-title>
        <v-card-text>
          <v-container>
          <v-form ref="formCadastro" lazy-validation>
            <v-row>
              <!-- campo para preencher email  -->
               <v-col cols="12">
              <v-text-field
                v-model="email_login"
                label="E-mail usado no login usuário"
                persistent-hint
                hint="Informe o e-mail do usuário que receberá as permissões de acesso"
                :disabled="desabilitarCampoConsultarCpf"
                append-icon="mdi-magnify"
                :rules="[val => !!val || 'Campo obrigatório']"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="cpf"
                label="CPF do usuário"
                counter="11"
                maxlength="11"
                @input="onInput"
                persistent-hint 
                hint="Informe o CPF do usuário que receberá as permissões de acesso"
                :loading="loadingCpf"
                :disabled="desabilitarCampoConsultarCpf"
                append-icon="mdi-magnify"
                :rules="[val => !!val || 'Campo obrigatório']"
              ></v-text-field>
            </v-col>
              <v-col cols="12">
              <v-text-field
                v-model="nome"
                label="Nome do servidor"
                persistent-hint
                hint="Nome do servidor é encontrado pelo CPF informado acima."
                :rules="[val => !!val || 'Campo obrigatório']"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="diretoria_id"
                :items="optionsDiretoria"
                item-text="label"
                item-value="value"
                label="Diretorias"
                :rules="[val => !!val || 'Campo obrigatório']"
                persistent-hint
                hint="Informe a diretoria do usuário."
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
            <v-autocomplete
              v-model="perfils_id"
              :items="optionsPerfis"
              item-text="label"
              item-value="value"
              label="Perfis"
              multiple
              chips
              :rules="[val => validarCampoPerfil()]"
              persistent-hint
              hint="Escolha os perfis para este usuário ou função."
            ></v-autocomplete>
            </v-col>
            <v-card-actions>
              <v-spacer></v-spacer>
           
              <v-btn color="primary" :loading="btnLoading" @click="acaoForm">
                <v-icon left>mdi-content-save</v-icon>Salvar
              </v-btn>
              <v-btn color="red" @click="modal = false">
                <v-icon left>mdi-close</v-icon>Cancelar
              </v-btn>
            </v-card-actions> 
          </v-row>

          </v-form>
        </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card-text>

    <TableDefaultComponent
      :columns="columns"
      :rows="rows"
      :btn-acao="btnAcao"
      @row-selected="selectedRowId = $event"
      @atualiza-status="atualizaStatus($event)"
    />
    </v-card-text>
  </v-card>
</template>

<script>
import api from '@/api'
import TableDefaultComponent from '../../components/TableDefaultComponent.vue'

const columns = [
  { name: 'id', align: 'center', text: 'ID', value: 'id' },
  { name: 'nome_usuario', align: 'center', text: 'Usuário', value: 'nome_usuario', sortable: true },
  { name: 'email', align: 'left', text: 'E-mail', value: 'email'},
  { name: 'diretoria_nome', align: 'center', text: 'Diretoria', value: 'diretoria_nome' },
  { name: 'nome_perfil', align: 'center', text: 'Perfil', value: 'nome_perfil'},
  { name: 'created_at', align: 'center', text: 'Data cadastro', value: 'created_at',  sortable:false, format: val => val ? new Date(val).toLocaleDateString() : ''},
  { name: 'status', align: 'center', text: 'Status', value: 'status' }
]
export default {
  name: 'PermissaoAcessosPage',
  components: {
    TableDefaultComponent
  },
  data () {
    return {
      tituloModal: 'Cadastro de permissao de acesso',

      usuario_id: '',
      nome: '',
      cpf: '',
      email_login: '',
      perfils_id: [],
      optionsPerfis: [],
      funcao_id: '',
      optionsFuncao: [],
      diretoria_id: '',
      optionsDiretoria: [],
      loadingCpf: false,
      validadeCPF: true,
      /// /////////////////

      btnLoading: false,
      modal: false,
      columns,
      rows: [],
      btnAcao: [
        { disable: false, icone: 'mdi-plus', color: 'primary', tooltip: 'Cadastro', acao: () => { this.modalForm('cadastro') } },
        { icone: 'mdi-pencil', color: 'secondary', tooltip: 'Edição', acao: () => { this.modalForm('edicao') } },
        { icone: 'mdi-delete', color: 'red', tooltip: 'Exclusão', acao: () => { this.deleteRegistro() } }
      ],
      selectedRowId: null,
      desabilitarCampoConsultarCpf: false,
      acaoForm: () => {}
    }
  },
  // watch: {
  //   // cpf (v) {
  //   //   // Remover caracteres não numéricos do CPF
  //   //   const cpfNumerico = v.replace(/\D/g, '')

  //   //   // Verificar se o CPF possui 11 dígitos numéricos
  //   //   if (cpfNumerico.length === 11 && this.desabilitarCampoConsultarCpf === false) {
  //   //     this.consultarCpf()
  //   //   }
  //   // }

  //   email_login(v) {
  //     // se conter @ no email, consultar usuario por email
  //     if (v.includes('@')) {
  //     this.consultarUsuarioPorEmail()
  //     }
  //   }
  // },
  created () {
    this.carregarRegistros()
    this.carregarPerfis()
    
    this.carregarDiretorias()
    // selecionar option do select
  },
  methods: {
    onInput() {
      this.cpf = this.cpf.replace(/\D/g, '')        
      },

    validarCampoDiretoria (val) {
      if (!val) { /// so valida se o campo está em branco se o tipo_permissao for igual a 1(usuario)
        return 'Campo obrigatório'
      } else {
        return true
      }
    },
    validarCampoCpf (val) {
      if (!val) { /// so valida se o campo está em branco se o tipo_permissao for igual a 2(Função)
        return 'Campo obrigatório'
      } else if (val.length < 14) {
        return 'CPF inválido'
      } else if (this.validadeCPF === false) {
        return 'CPF não encontrado'
      } else {
        return true
      }
    },
    validarCampoPerfil () {
      console.log(this.perfils_id.length)
      if (this.perfils_id.length === 0) {
        return 'Campo obrigatório'
      } else {
        return true
      }
    },
    consultarCpf () {
      this.loadingCpf = true
      api.get('consultar-cpf/' + this.cpf)
        .then(response => {
          if (!response.data.nome) {

            this.nome = ''
            this.usuario_id = ''
            this.loadingCpf = false
            this.validadeCPF = false
            return false
          }
          this.nome = response.data.nome
          this.usuario_id = response.data.id
          this.validadeCPF = true
          this.loadingCpf = false
        })
        .catch((e) => {
          alert(e.response.data.message)
          this.loadingCpf = false
        })
    },
    consultarUsuarioPorEmail () {
      this.loadingCpf = true
      api.get('consultar-usuario-por-email/' + this.email)
        .then(response => {
          if (!response.data.nome) {

            this.nome = ''
            this.usuario_id = ''
            this.loadingCpf = false
            this.validadeCPF = false
            return false
          }
          this.nome = response.data.nome
          this.usuario_id = response.data.id
          this.validadeCPF = true
          this.loadingCpf = false
        })
        .catch((e) => {
          alert(e.response.data.message)
          this.loadingCpf = false
        })
    },
    carregarPerfis () {
      api.get('carregar-perfis')
        .then(response => {
          this.optionsPerfis = response.data
        })
        .catch((erro) => {
          console.error(erro)
        })
    },
    carregarRegistros () {
      api.get('permissao-carregar')
        .then(response => {
          this.rows = response.data
        })
        .finally(() => {
          this.carregando = false
        })
        .catch(() => {})
    },

    carregarDiretorias () {
      api.get('permissao-carregar-diretorias')
        .then(response => {
          this.optionsDiretoria = response.data
        })
        .catch((erro) => {
          console.error(erro)
        })
    },
    modalForm (acao) {
      this.modal = true
      this.limpaForm()

      if (acao === 'edicao') {
        this.tituloModal = 'Editar permissão de acesso'
        this.getRegistro()
        this.acaoForm = () => { this.updateRegistro() }
        this.desabilitarCampoConsultarCpf = true
      } else {
        this.acaoForm = () => { this.salvarRegistro() }
        this.tituloModal = 'Cadastrar permissão de acesso'
        this.desabilitarCampoConsultarCpf = false
      }
    },
    getRegistro () {  
      api.get(`permissao-consulta/${this.selectedRowId}`)
        .then((e) => {
              this.diretoria_id = e.data.diretoria_id
              this.usuario_id = e.data.usuario_id
              this.perfils_id = e.data.perfils_id
              this.nome = e.data.nome_usuario
              this.cpf = e.data.cpf_usuario
        })
        .catch(() => {})
    },
    salvarRegistro () {
      this.$refs.formCadastro.validate()
      const formData = {
        usuario_id: this.usuario_id,
        perfils_id: this.perfils_id,
        diretoria_id: this.diretoria_id,
        
        
        cpf: this.cpf,
        nome: this.nome
      }
      this.btnLoading = true
      api.post('permissao-cadastro', formData)
        .then(() => {
         
          this.carregarRegistros()
          this.modal = false
        })
        .catch(() => {})
      this.btnLoading = false
    },
    atualizaStatus (row) {
      
      api.put(`permissao-status/${row.id}`, row)
        .then()
        .catch((e) => {
          console.error(e)
        })
    },
    updateRegistro () {
      this.btnLoading = true

      api.put('permissao-edicao',
        {
          usuario_id: this.usuario_id,
          perfils_id: this.perfils_id,
          
          diretoria_id: this.diretoria_id,
          
          cpf: this.cpf,
          nome: this.nome,
          id: this.selectedRowId
        })
        .then(() => {
          this.carregarRegistros()
          this.modal = false
        })
        .catch(() => {})
      this.btnLoading = false
    },

    deleteRegistro () {
    const c = confirm('Deseja realmente excluir este registro?');
    if(c){
        api.delete(`permissao-exclusao/${this.selectedRowId}`)
          .then((response) => {
            if (response.data.results === true) {
              this.carregarRegistros()
              this.modal = false
            } else {
              console.error(response.data.msg)
            }
          })
          .catch(() => {})
        }
    },

    limpaForm () {
      
      this.nome = ''
      this.cpf = ''
      this.perfils_id = []
      
      this.diretoria_id = ''
      this.btnLoading = false
      this.usuario_id = ''
    }
  }
}
</script>